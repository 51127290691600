<template>
  <div class="sls">
    <!-- <navbar></navbar> -->
    <hero
      title="We're Lost"
      subtitle="It's OK. We just need to look up!"
    ></hero>
    <!-- <browse></browse> -->
    <!-- <feedback></feedback> -->
    <!-- <div class="footer">
      created by <router-link :to="{name: 'home'}">maybelatergames</router-link>
    </div> -->
    <!-- Preload images for new game -->
    <!-- <img v-show="false" src="./../assets/backgrounds/ironcladPortrait.jpg" alt="">
    <img v-show="false" src="./../assets/backgrounds/silentPortrait.jpg" alt=""> -->
  </div>
</template>

<script>
import navbar from './nav';
import hero from './hero';
import browse from './browse';
import feedback from './feedback';

export default {
  name: 'sts-home',
  components: { navbar, hero, browse, feedback },
  data() {
    return {
      test: false,
    };
  },
  // filtering
  // mounted: function(){
  //   (function(d) {
  //     var config = {
  //       kitId: 'kpj2pou',
  //       scriptTimeout: 3000,
  //       async: true
  //     },
  //     h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  //   })(document);
  // },
  computed: {
    filteredList() {
      return this.postList.filter((post) =>
        post.title.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.sls {
  min-height: 100vh;
  // background: #03053b;
  color: #fff6e1;
  font-weight: 300;
  background: #10212b;
  background-image: url('./../assets/backgrounds/slshero.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 0 0 1px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer {
  margin: 180px 0;
  text-align: center;
}
</style>
